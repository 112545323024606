import React from "react"
import styled from "styled-components"
import "./layout.css"
import Header from "./header"

const Container = styled.main`
  max-width: 800px;
  margin: 0 auto;
`

export default function Layout({ children }: { children: any }) {
  return (
    <>
      <Header />
      <Container>{children}</Container>
    </>
  )
}
