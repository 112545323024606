import React from "react"
import styled from "styled-components"
import useVibrant from "use-vibrant-hook"

interface Props {
  title: string
  description: string
  tech?: string[]
  image: any
}

const Container = styled.section`
  min-height: 400px;
  position: relative;
  background-image: url(${(props: { image: string; border: string }) =>
    props.image});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid
    ${(props: { image: string; border: string }) => props.border};
  margin-bottom: 20px;

  .content {
    position: relative;
    z-index: 100;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  }
`

const Overlay = styled.div`
  background-image: linear-gradient(
    to bottom right,
    ${(props: { from: string; to: string }) => props.from},
    ${(props: { from: string; to: string }) => props.to}
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 10;
`

export default function Project({ title, description, tech, image }: Props) {
  const { done, colors } = useVibrant(image)
  return (
    <>
      {done && (
        <Container image={image} border={colors.Vibrant.hex}>
          <Overlay
            from={colors && colors.Muted.hex}
            to={colors.LightVibrant.hex}
          />

          <div className="content">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </Container>
      )}
    </>
  )
}
