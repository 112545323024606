import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import Project from "../components/Project"

const H3 = styled.h3`
  font-size: 25px;
`

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <H3>Projects</H3>
      <Project
        title="MASCGB"
        description="The website for the Miniature American Shepherd Club of Great Britain"
        image={require(`../images/mascgb.png`)}
      />

      <Project
        title="MAS Agility League"
        description="Firebase application for running an agility league"
        image={require(`../images/masleague.png`)}
      />

      <Project
        title="Agility Notebook"
        description="Appliation for planning future agility runs and shows, as well as recording results"
        image={require(`../images/agilitynotebook.png`)}
      />

      <h3>Open Source</h3>
      <div>
        <h4>useVibrant</h4>
        <p>
          A react hook wrapper around the{" "}
          <a href="https://github.com/akfish/node-vibrant#readme">
            node-vibrant library
          </a>
        </p>
        <p>Used on this site, as well as the MASCGB website.</p>
        <ul>
          <li>
            <a href="https://github.com/ymhr/useVibrant">Github</a>
          </li>
          <li>
            <a href="https://www.npmjs.com/package/use-vibrant-hook">NPM</a>
          </li>
        </ul>

        <h4>object-traversal-by-path</h4>
        <p>
          Snappily named library for traversing through an object using a string
          path. Can also mutate the values at the end.
        </p>
        <p>Not currently used.</p>
        <ul>
          <li>
            <a href="https://github.com/ymhr/object-traversal-by-path">
              Github
            </a>
          </li>
          <li>
            <a href="https://www.npmjs.com/package/object-traversal-by-path">
              NPM
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}
